import { MENU_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import type { PopulatedMenu } from '../../types/menusTypes';
import type { Experiments, I$W, TFunction } from '@wix/yoshi-flow-editor';
import { context } from '../../context/RootContext';
import { MENU_STATES } from '../../types/businessTypes';
import { menusState } from 'root/states/MenusState';
import { autorun } from 'mobx';
import { availabilityStatusKeys } from 'root/availabilityStatusKeys';
import {
  LiveSiteClickFulfillmentOrigin,
  LiveSiteMenuStatusCardDisplayedPageName,
} from '@wix/restaurants-bi';
import { dispatchState } from 'root/states/DispatchState';
import { openDispatchModal } from 'root/utils/utils';
import { navigationMenuState } from 'root/states/NavigationMenuState';

export class MenusController {
  sectionsIdsMap: Record<string, string> = {};
  shouldShowErrorContent: boolean = false;

  constructor(
    private $w: I$W,
    private t: TFunction,
    private experiments: Experiments,
    private isEditor: boolean,
    private timezone: string,
    private locale: string,
    private isMemberLoggedIn: boolean
  ) {
    context.pubsub.subscribe('onScrollToMenu', this.scrollToMenu);
  }

  setMenus(menus: PopulatedMenu[]) {
    const msb = this.$w(MENU_WIDGET_COMPONENT_IDS.menuMultiStateBox);
    msb.changeState(MENU_STATES.menu);
    this.$w(MENU_WIDGET_COMPONENT_IDS.repeaterMenus).data = menus.map(({ id, ...rest }) => ({
      _id: id,
      ...rest,
    }));
  }

  setEmptyState() {
    const msb = this.$w(MENU_WIDGET_COMPONENT_IDS.menuMultiStateBox);
    msb.changeState(MENU_STATES.menuEmptystate);
    this.$w(MENU_WIDGET_COMPONENT_IDS.menuEmptyStateTitle).text = this.t(
      'menu_olo.emptyState.title'
    );
    this.$w(MENU_WIDGET_COMPONENT_IDS.menuEmptyStateSubtitle).text = this.t(
      'menu_olo.emptyState.subTitle'
    );
  }

  async init(menus: PopulatedMenu[]) {
    this.$w(MENU_WIDGET_COMPONENT_IDS.repeaterMenus).onItemReady(
      ($item: I$W, itemData: PopulatedMenu & { _id: string }) => {
        const title = $item(MENU_WIDGET_COMPONENT_IDS.menuTitle);
        title.text = itemData.name ?? '';
        if (!itemData.name) {
          title.collapse();
        } else {
          title.collapsed && title.expand();
        }

        const description = $item(MENU_WIDGET_COMPONENT_IDS.menuDescription);
        description.text = itemData.description ?? '';
        if (!itemData.description) {
          description.collapse();
        } else {
          description.collapsed && description.expand();
        }

        const menuState = menusState.getMenu(itemData._id);
        const menuStatusElement = $item(MENU_WIDGET_COMPONENT_IDS.menuStatus);
        if (this.isEditor) {
          !menuStatusElement.deleted && menuStatusElement.delete();
        } else {
          autorun(() => {
            const isAvailable = !!menuState?.isAvailable;
            const { shouldCollapseAvailabilityStatus } =
              menuState?.getAvailabilityStatus({
                locale: this.locale,
                timezone: this.timezone,
                t: this.t,
                keys: availabilityStatusKeys.menu,
              }) ?? {};

            if (!isAvailable && menuState?.nextAvailableTimeslot !== undefined) {
              context.biReporterService?.reportOloMenuStatusCardDisplayedBiEvent({
                menuId: itemData.id,
                isButtonDisplayed: !!menuState?.nextAvailableTimeslot,
                pageName: LiveSiteMenuStatusCardDisplayedPageName.OLO_PAGE,
                isCardDisplayed: !shouldCollapseAvailabilityStatus,
              });
            }

            if (isAvailable || !!shouldCollapseAvailabilityStatus) {
              !menuStatusElement.collapsed && menuStatusElement.collapse();
            } else {
              menuStatusElement.collapsed && menuStatusElement.expand();
            }
          });
        }

        if (!this.isEditor) {
          const menuStatusTextElement = $item(MENU_WIDGET_COMPONENT_IDS.menuStatusText);
          const menuStatusButtonElement = $item(MENU_WIDGET_COMPONENT_IDS.menuStatusButton);

          autorun(() => {
            const { text = '' } =
              menuState?.getAvailabilityStatus({
                locale: this.locale,
                timezone: this.timezone,
                t: this.t,
                keys: availabilityStatusKeys.menu,
              }) ?? {};
            menuStatusTextElement.text = text;
            if (dispatchState.selectedDispatchType) {
              menuStatusButtonElement.label = this.t(
                `menu_olo.menuAvailabilityStatus.nextAvailable.${dispatchState.selectedDispatchType.toLowerCase()}.button`
              );
            }
            menuStatusButtonElement.onClick(() => {
              context.biReporterService?.reportOloLiveSiteClickOnFulfillmentBiEvent({
                origin: LiveSiteClickFulfillmentOrigin.CLICK_MENU_STATUS_BUTTON_OLO_PAGE,
                dispatchType: dispatchState.selectedDispatchType,
                isMemberLoggedIn: this.isMemberLoggedIn,
                menuId: itemData._id,
              });
              openDispatchModal({
                onSave: async ({ dispatchType, dispatchInfo }) => {
                  dispatchState.update(dispatchType, dispatchInfo);
                  context.CartService?.setShippingDetails(dispatchState.getShippingDetails());
                  await menusState.updateAvailabilityStatus(
                    dispatchState.currentOperationId,
                    dispatchInfo,
                    dispatchType
                  );
                },
                context,
                dispatchState: dispatchState.state,
              });
            });

            const hasNextAvailableTime = !!menuState?.nextAvailableTimeslot;
            if (!hasNextAvailableTime) {
              !menuStatusButtonElement.collapsed && menuStatusButtonElement.collapse();
            } else {
              menuStatusButtonElement.collapsed && menuStatusButtonElement.expand();
            }
          });
        }

        const divider = $item(MENU_WIDGET_COMPONENT_IDS.menuDivider);
        if (!itemData.sections.length) {
          divider.collapse();
        } else {
          divider.collapsed && divider.expand();
        }

        const sectionContainer = $item(MENU_WIDGET_COMPONENT_IDS.sectionContainer);
        sectionContainer.data = {
          sections: itemData.sections,
          menuId: itemData._id,
        };
      }
    );
    this.setMenus(menus);
  }

  scrollToMenu = ({ menuId }: { menuId: string }) => {
    navigationMenuState.setNavigating(true);
    setTimeout(() => {
      this.$w(MENU_WIDGET_COMPONENT_IDS.repeaterMenus).forItems([menuId], async ($item: I$W) => {
        await $item(MENU_WIDGET_COMPONENT_IDS.menuTitle).scrollTo();
        navigationMenuState.setNavigating(false);
      });
    }, 0);
  };
}
