import type {
  I$W,
  IWixWindow,
  PlatformControllerFlowAPI,
  TFunction,
  ISiteApis,
} from '@wix/yoshi-flow-editor';
import type {
  DispatchStateByLocation,
  DispatchType,
  Operation,
  OSLocation,
} from 'root/types/businessTypes';
import { SchedulingType } from 'root/types/businessTypes';
import { DISPATCH_MODAL_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import { autorun, toJS, reaction } from 'mobx';
import { LiveSiteClicksInsideDispatchModalButtonType } from '@wix/restaurants-bi';
import type { DispatchInfo, AddressInputAddress } from '../../types/businessTypes';
import type { IBIReporterService } from 'root/services/biReporterService';
import { DispatchModalForm } from './dispatchModalForm';
import type { DispatchModalStore } from 'root/states/DispatchModalStore';
import { BIEventsHandler, ErrorType, isValidDate } from './dispatchModalUtils';
import { dispatchState } from 'root/states/DispatchState';
import { getASAPString } from '../Header/headerUtils';
import type { BindAll, IDispatchModalController } from './types';
import { DEFAULT_TIMEZONE } from 'root/api/consts';
import { getDateFormat } from 'root/utils/dateTimeUtils';
import { fulfillmentPickerStore } from 'root/states/FulfillmentPickerStore';
import { SPECS } from 'root/appConsts/experiments';
import { getSiteLocale } from 'root/utils/siteDataUtils';

export class DispatchModalController implements IDispatchModalController {
  private form!: DispatchModalForm;
  constructor(
    private $bindAll: BindAll,
    private $w: I$W,
    private flowAPI: PlatformControllerFlowAPI,
    private window: IWixWindow,
    private isEditor: boolean,
    private store: DispatchModalStore,
    private operation: Operation,
    private locations: (OSLocation & { _id: string; acceptOrders: boolean })[] | undefined,
    private onSave: ({
      dispatchType,
      dispatchInfo,
      operationId,
    }: {
      dispatchType: DispatchType;
      dispatchInfo: DispatchInfo;
      operationId: string;
    }) => void,
    private biReporterService?: IBIReporterService,
    private isViewer: boolean = true
  ) {
    const {
      flowAPI: {
        translations,
        experiments,
        controllerConfig: {
          wixCodeApi: { site },
        },
        errorMonitor,
        reportError,
      },
    } = this;
    const t = translations.t as TFunction;
    const timezone = site.timezone || DEFAULT_TIMEZONE;
    const locale = getSiteLocale(this.flowAPI);
    this.form = new DispatchModalForm(store, t, timezone, locale, errorMonitor, reportError);
  }

  init(
    onModalOpen: () => void,
    closeModal: (
      window: IWixWindow,
      data?: { dispatchType: DispatchType; dispatchInfo: DispatchInfo }
    ) => void,
    initialDispatchState?: DispatchStateByLocation
  ) {
    const t = this.flowAPI.translations.t as TFunction;
    const site = this.flowAPI.controllerConfig.wixCodeApi.site as ISiteApis;
    const isMemberLoggedIn = !!this.flowAPI.controllerConfig.wixCodeApi.user.currentUser.loggedIn;

    const biHandler = new BIEventsHandler(
      this.form,
      dispatchState.configuredDispatchTypes,
      this.operation,
      isMemberLoggedIn,
      this.store.schedulingType,
      this.biReporterService,
      initialDispatchState
    );

    const timezone = site.timezone || DEFAULT_TIMEZONE;
    const locale = getSiteLocale(this.flowAPI);
    const isMultiLocation =
      this.flowAPI.experiments.enabled(SPECS.enableMultiLocation) &&
      dispatchState.dispatchStateByLocation.size > 1;

    autorun(() => {
      const multiStateBox = this.$w(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.dispatchStateBox);
      multiStateBox.changeState(this.form.dispatchType.toLowerCase());
    });

    this.flowAPI.errorMonitor?.addBreadcrumb({
      category: 'DispatchModalController',
      message: 'init',
      data: { store: this.store },
    });

    const onDispatchTypeChange = async (dispatchType: DispatchType) => {
      biHandler.onDispatchTypeChange(dispatchType);
      await this.form.setDispatchType(dispatchType);
    };

    autorun(() => {
      fulfillmentPickerStore.setObservableProps({
        selectedDispatchType: this.form.dispatchType,
        onDispatchTypeChange,
        hasPopup: false,
      });
    });

    reaction(
      () => !this.store.isLoading,
      () => this.changeSchedulingTypeModalState()
    );

    try {
      this.changeSchedulingTypeModalState();
      this.$bindAll({
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.multipleDelivery]: {
          deleted: () => this.isEditor,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.changeLocation]: {
          deleted: () => this.isEditor,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.locationNamePickupDispatch]: {
          deleted: () => true,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.locationLobby]: {
          deleted: () => this.isEditor,
          collapsed: () => !isMultiLocation,
          hidden: () => !isMultiLocation,
          data: () => (isMultiLocation ? this.locations : []),
          item: (
            itemData: OSLocation & { _id: string; acceptOrders: boolean },
            bindItem: Function
          ) => {
            bindItem(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.pickupLocationName, {
              text: () => itemData.name,
              collapsed: () => false,
            });
            bindItem(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.pickupLocationAddress, {
              text: () => itemData.address?.formattedAddress,
            });
          },
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.NotAcceptingError]: {
          deleted: () => this.isEditor,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.deliverFrom]: {
          deleted: () => this.isEditor,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.safteyBoxForChangeLocation]: {
          deleted: () => this.isEditor,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.safteyBoxForError]: {
          deleted: () => this.isEditor,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.timeSlots]: {
          collapsed: () =>
            this.form.hasError ||
            !this.form.isScheduleForLater ||
            (this.form.dropdownTimeSlotsOptions.length === 0 && !this.store.isLoading),
          value: () => {
            if (!this.isViewer) {
              setTimeout(
                () =>
                  this.$w(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.timeSlots).resetValidityIndication(),
                0
              );
            }
            return this.form.selectedOption?.value;
          },
          options: () => this.form.dropdownTimeSlotsOptions,
          onChange: (event: $w.Event) => {
            biHandler.onTimeSlotChange();
            this.form.setSelectedOption(event.target.value);
          },
          onClick: biHandler.onTimeSlotClick,
          label: () => t('menu_olo.dispatchModal.timePicker'),
          disabled: () => this.store.isLoading,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.dateSlots]: {
          collapsed: () => this.form.shouldCollapseDateSlotDropdown,
          disabled: () => this.store.isLoading,
          value: () => {
            if (!this.isViewer) {
              setTimeout(
                () =>
                  this.$w(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.dateSlots).resetValidityIndication(),
                0
              );
            }
            return this.form.selectedDate?.toDateString();
          },
          options: () => this.form.dropdownDatesOptions,
          onChange: (event: $w.Event) => {
            biHandler.onDateSlotChange();
            this.form.setSelectedDate(new Date(event.target.value));
          },
          onClick: biHandler.onDateSlotClick,
          label: () => t('menu_olo.dispatchModal.datePicker'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.saveButton]: {
          onClick: async () => {
            const { selectedDispatchType: dispatchType, dispatchInfo } = dispatchState;
            biHandler.onSaveButtonClick(dispatchInfo.selectedTimeSlot);
            const canSubmit = await this.form.canSubmit();
            if (canSubmit) {
              this.onSave({ dispatchType, dispatchInfo, operationId: this.operation.id });
              closeModal(this.window, { dispatchType, dispatchInfo });
            }
          },
          disabled: () => this.store.isLoading || !this.form.address,
          label: () => t('menu_olo.dispatchModal.saveButton'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.addressInput]: {
          onChange: async (evt: $w.Event) => {
            await this.form.setAddress(evt.target.value as AddressInputAddress);
            if (this.form.hasError) {
              this.form.errorText === ErrorType.ADDRESS_NOT_FOUND.toString()
                ? biHandler.onAddressNotFound()
                : biHandler.onNoAvailableFulfillments();
            }
          },
          onClick: biHandler.onAddressInputClick,
          value: () => toJS(this.form.address),
          label: () => t('menu_olo.dispatchModal.delivery.to'),
          placeholder: () => t('menu_olo.dispatchModal.delivery.addressInput.placeholder'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.asapText]: {
          text: () => getASAPString(this.store.asapTimeExact, this.store.asapTimeRange, t),
          collapsed: () =>
            this.form.hasError || !(this.store.asapTimeExact || this.store.asapTimeRange),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.closeButton]: {
          onClick: () => {
            biHandler.onCloseButtonClick(dispatchState.dispatchInfo.selectedTimeSlot);
            closeModal(this.window);
          },
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.errorMessage]: {
          text: () => this.form.errorText || '',
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.errorContainer]: {
          collapsed: () =>
            !this.form.hasError || this.form.errorText === ErrorType.ADDRESS_NOT_FOUND.toString(),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.datePickerContainer]: {
          collapsed: () =>
            !this.form.address ||
            this.form.hasError ||
            (this.form.dropdownTimeSlotsOptions.length === 0 && !this.store.isLoading),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.datePicker]: {
          disabled: () => this.store.isLoading,
          onChange: async (evt: $w.Event) => {
            await this.form.setSelectedDate(evt.target.value);
          },
          onViewChange: async (evt: $w.Event) => {
            // @ts-expect-error
            const { startDate: from, endDate: until } = evt.options ?? {};
            if (isValidDate(timezone, until)) {
              await this.store.setAvailableDates({ from, until });
            }
          },
          onClick: biHandler.onDatePickerClick,
          dateFormat: () => getDateFormat(locale),
          value: () => this.form.selectedDate,
          label: () => t('menu_olo.dispatchModal.datePicker'),
          enabledDateRanges: () => [
            ...this.store.availableDateRanges,
            ...(this.form.selectedDate
              ? [{ startDate: this.form.selectedDate, endDate: this.form.selectedDate }]
              : []),
          ],
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.dateTimeSlots]: {
          options: () => this.form.dropdownTimeSlotsOptions,
          onChange: (evt: $w.Event) => this.form.setSelectedOption(evt.target.value),
          onClick: () =>
            this.biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
              dispatchType: this.form.dispatchType,
              isPreorder: this.form.isPreOrder,
              buttonType: LiveSiteClicksInsideDispatchModalButtonType.TIME_DROPDOWN,
              operationId: this.operation.id,
            }),
          label: () => t('menu_olo.dispatchModal.timePicker'),
          value: () => {
            if (!this.isViewer) {
              setTimeout(
                () =>
                  this.$w(
                    DISPATCH_MODAL_WIDGET_COMPONENT_IDS.dateTimeSlots
                  ).resetValidityIndication(),
                0
              );
            }
            return this.form.selectedOption?.value;
          },
          collapsed: () => this.form.dropdownTimeSlotsOptions.length === 0 && !this.store.isLoading,
          disabled: () => this.store.isLoading,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.schedulingPicker]: {
          collapsed: () => !this.form.canSubmitOrderForNow,
          onChange: (evt: $w.Event) => {
            biHandler.onSchedulingTypeChange(evt.target.value);
            this.form.setSchedulingType(evt.target.value);
          },
          disabled: () => this.form.hasError || this.store.isLoading,
          options: () => [
            {
              value: SchedulingType.ASAP,
              label: getASAPString(this.store.asapTimeExact, this.store.asapTimeRange, t),
            },
            {
              value: SchedulingType.ASAP_AND_FUTURE,
              label: t('menu_olo.dispatch.time.Later'),
            },
          ],
          value: () => this.form.schedulingType,
          label: () => t('menu_olo.dispatchModal.delivery.when'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.timeMultiState]: {
          collapsed: () => this.form.shouldCollapseTimeMultiStateBox,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.fulfillmentPicker]: {
          collapsed: () => !this.store.hasMoreThanOneDispatchType,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.pickupAddress]: {
          text: () => this.store.pickupAddress?.formattedAddress ?? '',
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.pickupHeader]: {
          text: () => t('menu_olo.dispatchModal.pickup.from'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.title]: {
          text: () => t('menu_olo.dispatchModal.headerTitle'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.asapTime]: {
          text: () => t('menu_olo.dispatchModal.delivery.when'),
          disabled: () => this.store.isLoading,
          collapsed: () =>
            this.form.hasError || !(this.store.asapTimeExact || this.store.asapTimeRange),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.preOrderTime]: {
          text: () => t('menu_olo.dispatchModal.delivery.when'),
          disabled: () => this.store.isLoading,
          collapsed: () =>
            this.form.hasError || (this.store.timeSlots.length === 0 && !this.store.isLoading),
        },
      });
      this.isViewer && onModalOpen();
      this.biReporterService?.reportOloLiveSiteDispatchModalOpenedBiEvent({
        isAsapDisplayed: this.form.canSubmitOrderForNow,
        availableDispatchTypes: dispatchState.configuredDispatchTypes,
        isPreorderDisplayed: this.form.isPreOrder,
        schedulingType: this.store.schedulingType,
        currentFulfillment: this.store.dispatchType,
        operationId: this.operation.id,
        isMemberLoggedIn,
        selectedSchedulingRadioButton: this.form.isSchedulingPickerDisplayed
          ? this.form.schedulingType
          : undefined,
        selectedTime: dispatchState.dispatchInfo.selectedTimeSlot,
        businessDays: this.operation.businessDaysAheadHandlingOptions,
      });
    } catch (e) {
      this.flowAPI.reportError(e as Error);
      this.flowAPI.errorMonitor?.captureException(e as Error);
    }
  }

  changeSchedulingTypeModalState() {
    const multiStateBox = this.$w(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.timeMultiState);
    multiStateBox.changeState(this.store.schedulingTypeModalState);
  }
}
