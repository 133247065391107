import type { LineItem } from '@wix/ambassador-ecom-v1-cart/types';
import { APP_DEF_IDS } from '@wix/restaurants-consts';
import type { CartLineItem } from '../services/cartService';
import { arrayToStringWithSeparator } from './utils';
import type { Address } from '../types/businessTypes';
import { DispatchType } from '../types/businessTypes';

export const enum MIXED_CART_TYPES {
  NONE,
  EXTERNAL = 'external',
  INTERNAL_PAGE = 'internal-page',
  INTERNAL_LOCATION = 'internal-location',
}

export const isCartContainMixedItems = (
  cartItems: LineItem[],
  operationId: string | undefined,
  cartLocationId?: string,
  currentLocationId?: string
) => {
  for (const item of cartItems) {
    if (item.catalogReference?.appId !== APP_DEF_IDS.orders) {
      return MIXED_CART_TYPES.EXTERNAL;
    } else if (cartLocationId !== currentLocationId) {
      return MIXED_CART_TYPES.INTERNAL_LOCATION;
    } else if (item.catalogReference?.options?.operationId !== operationId) {
      return MIXED_CART_TYPES.INTERNAL_PAGE;
    }
  }
  return MIXED_CART_TYPES.NONE;
};

export const findFullCartItem = (cartItems: LineItem[], item: CartLineItem) => {
  const catalogLineItems =
    cartItems?.filter((i) => i.catalogReference?.catalogItemId === item.catalogItemId) ?? [];

  const currentLineItemsIds = cartItems?.map((cartItem) => cartItem.id);
  return (
    catalogLineItems?.find((i) => currentLineItemsIds?.every((id) => id !== i.id)) ??
    catalogLineItems[0]
  );
};

const ASAP_CODE = 'ASAP';
const SHIPPING_CODE_SEPARATOR = '|';
export const getShippingOptionCode = (
  dispatchType: DispatchType,
  timeRange:
    | {
        start: number;
        end: number;
      }
    | undefined
) => {
  const timeValues = timeRange ? [timeRange.start, timeRange.end] : [ASAP_CODE];

  const shippingOptionCodeValues = [dispatchType, ...timeValues];
  return arrayToStringWithSeparator(shippingOptionCodeValues, SHIPPING_CODE_SEPARATOR);
};

export const getCartItemById = (cartLineItems: Map<string, CartLineItem[]>, cartItemId: string) => {
  for (const [, lineItems] of cartLineItems.entries()) {
    const cartItem = lineItems.find((lineItem) => lineItem.id === cartItemId);

    if (cartItem) {
      return cartItem;
    }
  }
};

export const buildAddressForCart = (dispatchType: DispatchType, address: Address): Address => {
  if (dispatchType === DispatchType.PICKUP) {
    const { country, subdivision } = address;
    return { country, subdivision };
  } else {
    const addressLine = address?.addressLine ?? address?.streetAddress?.formattedAddressLine;
    return {
      ...address,
      addressLine,
      streetAddress: undefined, // ecom graphql createCheckout cannot contain both addressLine and streetAddress
    };
  }
};
