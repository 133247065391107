import { makeObservable, runInAction, observable, action, configure } from 'mobx';
import type { Item } from 'root/types/item';
import type { PopulatedMenu } from 'root/types/menusTypes';

configure({ isolateGlobalState: true });

class ItemState {
  private items: Map<string, Item> = new Map();
  isFetching = true;
  constructor() {
    makeObservable(this, {
      isFetching: observable,
      setItemMap: action,
    });
  }

  get itemMap() {
    return this.items;
  }

  setItemMap(items: Map<string, Item>) {
    this.items = items;
    runInAction(() => {
      this.isFetching = false;
    });
  }

  setItemMapFromPopulatedMenus(menus: PopulatedMenu[]) {
    const items = new Map<string, Item>();
    menus.forEach((menu) => {
      menu.sections.forEach((section) => {
        section.items?.forEach((item) => {
          items.set(item.id, item);
        });
      });
    });
    this.setItemMap(items);
  }
}

export const itemState = new ItemState();
