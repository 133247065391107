import type { DispatchTime, DispatchInfo } from 'root/types/businessTypes';
import { BusinessOpenStatus, SchedulingType, DispatchType } from 'root/types/businessTypes';
import { makeObservable, computed, action, toJS, observable, configure } from 'mobx';
import { dispatchState } from './DispatchState';

configure({ isolateGlobalState: true });

export class HeaderStore {
  _isMultiLocation: boolean = false;
  constructor(private timezone: string, private locale: string) {
    makeObservable(this, {
      _isMultiLocation: observable,
      dispatchType: computed,
      openStatus: computed,
      selectedAddress: computed,
      selectedTime: computed,
      asapTimeExact: computed,
      asapTimeRange: computed,
      minOrder: computed,
      deliveryFee: computed,
      schedulingType: computed,
      isASAP: computed,
      isDeliveryAvailable: computed,
      isPickupAvailable: computed,
      isDeliveryConfigured: computed,
      isPickupConfigured: computed,
      hasConfiguredDispatches: computed,
      hasAvailableDispatches: computed,
      pickupAddress: computed,
      hasDispatchDetails: computed,
      dispatchState: computed,
      isLoading: computed,
      setIsMultiLocation: action,
      updateDispatchState: action,
    });
  }

  get operation() {
    return dispatchState.currentOperation;
  }

  get location() {
    return this.operation?.locationDetails;
  }

  get isMultiLocation() {
    return this._isMultiLocation;
  }

  setIsMultiLocation(isMultiLocation: boolean) {
    this._isMultiLocation = isMultiLocation;
  }

  get isLoading() {
    return dispatchState.isLoading;
  }

  get dispatchType() {
    return dispatchState.selectedDispatchType;
  }

  get openStatus() {
    return this.operation?.operationType !== 'PRE_ORDER' || this.hasAvailableDispatches
      ? BusinessOpenStatus.Open
      : BusinessOpenStatus.Closed;
  }

  get isOpen() {
    return this.openStatus === BusinessOpenStatus.Open;
  }

  get isDelivery() {
    return this.dispatchType === DispatchType.DELIVERY;
  }

  get isPickup() {
    return this.dispatchType === DispatchType.PICKUP;
  }

  get selectedAddress() {
    return toJS(dispatchState.dispatchInfo.address);
  }

  get selectedTime(): DispatchTime | undefined {
    const { selectedTimeSlot: { startsNow, startTime, endTime } = {} } = dispatchState.dispatchInfo;
    const haveSelectedTime = !startsNow && !!(startTime && endTime);
    if (haveSelectedTime) {
      return {
        from: startTime.setZone(this.timezone).setLocale(this.locale),
        until: endTime.setZone(this.timezone).setLocale(this.locale),
      };
    }
    return undefined;
  }

  get asapTimeExact() {
    const { address, selectedTimeSlot, maxTimeOptions } = dispatchState.dispatchInfo;
    if (address && selectedTimeSlot?.startsNow) {
      return selectedTimeSlot.fulfillmentDetails.maxTimeOptions;
    }
    return this.schedulingType !== SchedulingType.PRE_ORDER ? maxTimeOptions : undefined;
  }

  get asapTimeRange() {
    const { address, selectedTimeSlot, durationRangeOptions } = dispatchState.dispatchInfo;
    if (address && selectedTimeSlot?.startsNow) {
      return selectedTimeSlot.fulfillmentDetails.durationRangeOptions;
    }
    return this.schedulingType !== SchedulingType.PRE_ORDER ? durationRangeOptions : undefined;
  }

  get minOrder() {
    const { address, selectedTimeSlot, minOrder } = dispatchState.dispatchInfo;
    if (address && selectedTimeSlot) {
      return selectedTimeSlot.fulfillmentDetails.minOrderPrice ?? undefined;
    }
    return minOrder;
  }

  get deliveryFee() {
    if (!this.isDelivery) {
      return undefined;
    }

    const { selectedTimeSlot, deliveryFee, address } = dispatchState.dispatchInfo;
    if (address && selectedTimeSlot) {
      return selectedTimeSlot.fulfillmentDetails.fee ?? undefined;
    }
    return deliveryFee;
  }

  get freeDispatchPriceThreshold() {
    const { address, freeFulfillmentPriceThreshold, selectedTimeSlot } = dispatchState.dispatchInfo;
    if (address && selectedTimeSlot) {
      return selectedTimeSlot.fulfillmentDetails?.freeFulfillmentPriceThreshold ?? undefined;
    }
    return freeFulfillmentPriceThreshold;
  }

  get schedulingType(): SchedulingType {
    if (this.operation?.operationType === 'ASAP') {
      return this.operation.allowAsapFutureHandling
        ? SchedulingType.ASAP_AND_FUTURE
        : SchedulingType.ASAP;
    }
    return SchedulingType.PRE_ORDER;
  }

  get isASAP() {
    return this.schedulingType === 'ASAP';
  }

  get startsNow() {
    return !!(this.isASAP || dispatchState.dispatchInfo.selectedTimeSlot?.startsNow);
  }

  get isPickupAvailable() {
    return dispatchState.isDispatchAvailable(DispatchType.PICKUP);
  }

  get isDeliveryAvailable() {
    return dispatchState.isDispatchAvailable(DispatchType.DELIVERY);
  }

  get isPickupConfigured() {
    return dispatchState.isDispatchConfigured(DispatchType.PICKUP);
  }

  get isDeliveryConfigured() {
    return dispatchState.isDispatchConfigured(DispatchType.DELIVERY);
  }

  get hasConfiguredDispatches() {
    return dispatchState.hasConfiguredDispatches;
  }

  get hasAvailableDispatches() {
    return dispatchState.hasAvailableDispatches;
  }

  get pickupAddress() {
    return toJS(dispatchState.dispatchesInfo[DispatchType.PICKUP]?.address);
  }

  get onlyDeliveryConfigured() {
    return this.isDeliveryConfigured && !this.isPickupConfigured;
  }

  get hasDispatchDetails() {
    return (
      (this.isASAP && !!(this.asapTimeExact || this.asapTimeRange)) ||
      !!this.selectedTime ||
      this.dispatchType === DispatchType.DELIVERY ||
      !!this.selectedAddress
    );
  }

  get dispatchState() {
    return toJS(dispatchState.state);
  }

  updateDispatchState(dispatchType: DispatchType, dispatchInfo: DispatchInfo) {
    dispatchState.update(dispatchType, dispatchInfo);
  }
}
