import type { I$W, TFunction } from '@wix/yoshi-flow-editor';
import { HEADER_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import { context } from '../../context/RootContext';
import type { BindAll, IHeaderController } from './types';
import { HeaderStore } from 'root/states/HeaderStore';
import {
  getCartShippingDetailsFromHeaderStore,
  STATUS_INDICATOR_COLORS,
  resolveTimeString,
  getOnDispatchTypeChange,
  openDispatchModal,
} from './headerUtils';
import { LiveSiteClickFulfillmentOrigin } from '@wix/restaurants-bi';
import type { Address } from 'root/types/businessTypes';
import { DispatchType } from 'root/types/businessTypes';
import { autorun } from 'mobx';
import { fulfillmentPickerStore } from 'root/states/FulfillmentPickerStore';
import { dispatchState } from 'root/states/DispatchState';

export class HeaderController implements IHeaderController {
  constructor(
    private $bindAll: BindAll,
    private $w: I$W,
    private t: TFunction,
    private addressFormatter: Function,
    private locale: string,
    private timezone: string,
    private isMemberLoggedIn: boolean | undefined,
    private isSSR: boolean,
    private isEditor: boolean,
    private supportMultiLocation: boolean,
    private currentLocationId: string
  ) {}

  init() {
    const headerStore = new HeaderStore(this.timezone, this.locale);

    // eslint-disable-next-line no-console
    console.log('headerController isSSR :>> ', this.isSSR);
    autorun(() => {
      if (!headerStore.isLoading) {
        if (!this.isSSR && headerStore.isOpen) {
          const shippingDetails = getCartShippingDetailsFromHeaderStore(headerStore);
          context.CartService?.setShippingDetails(shippingDetails);
        }

        const isMultiLocation = this.supportMultiLocation && dispatchState.operations.length > 1;
        if (isMultiLocation !== headerStore.isMultiLocation) {
          headerStore.setIsMultiLocation(isMultiLocation);
        }
      }
    });

    context.initialTimeSlotText = resolveTimeString({
      asapOptions: {
        isASAP: headerStore.isASAP,
        exactTime: headerStore.asapTimeExact,
        timeRange: headerStore.asapTimeRange,
      },
      selectedTime: headerStore.selectedTime,
      t: this.t,
      timezone: this.timezone,
      dispatchType: headerStore.dispatchType,
    });

    const formatPrice = (price?: string) => {
      let formattedMin = '';
      if (typeof price !== 'undefined') {
        formattedMin = context.priceFormatter(Number(price));
      }
      return formattedMin;
    };

    const formatAddress = (address?: Address) => {
      let formattedAddress = '';
      if (address && this.addressFormatter) {
        formattedAddress = this.addressFormatter({ address }, { appendCountry: false })
          .filter((part: string) => part && /\S/.test(part))
          .join(', ');
      }
      return (
        (formattedAddress ||
          address?.formattedAddress ||
          address?.addressLine ||
          address?.streetAddress?.formattedAddressLine) ??
        ''
      );
    };

    autorun(() => {
      fulfillmentPickerStore.setObservableProps({
        selectedDispatchType: headerStore.dispatchType,
        onDispatchTypeChange: getOnDispatchTypeChange(headerStore, this.isMemberLoggedIn),
        hasPopup: true,
      });
    });

    this.$bindAll({
      [HEADER_WIDGET_COMPONENT_IDS.badgesContainer]: {
        hidden: () => headerStore.isLoading,
      },
      [HEADER_WIDGET_COMPONENT_IDS.startOrder]: {
        deleted: () => this.isEditor,
        collapsed: () => !(headerStore.isMultiLocation && !this.currentLocationId),
        hidden: () => !(headerStore.isMultiLocation && !this.currentLocationId),
      },
      [HEADER_WIDGET_COMPONENT_IDS.startOrderButton]: {
        onClick: async () => {
          context.biReporterService?.reportOloLiveSiteClickOnFulfillmentBiEvent({
            origin: LiveSiteClickFulfillmentOrigin.START_ORDER,
            isMemberLoggedIn: this.isMemberLoggedIn,
          });
          openDispatchModal(headerStore, headerStore.dispatchState);
        },
      },
      [HEADER_WIDGET_COMPONENT_IDS.locationNameWrapper]: {
        deleted: () => this.isEditor,
        collapsed: () => !headerStore.isMultiLocation,
        hidden: () => !headerStore.isMultiLocation,
      },
      [HEADER_WIDGET_COMPONENT_IDS.locationName]: {
        text: () => headerStore.location?.name ?? '',
      },
      [HEADER_WIDGET_COMPONENT_IDS.menuStatusText]: {
        text: () =>
          this.t('header_olo.multiLocationNotification', { location: headerStore.location?.name }),
      },
      [HEADER_WIDGET_COMPONENT_IDS.fulfillmentContainer]: {
        hidden: () => headerStore.isLoading,
        deleted: () => !headerStore.isLoading && !headerStore.hasConfiguredDispatches,
      },
      [HEADER_WIDGET_COMPONENT_IDS.headerContainer]: {
        onViewportEnter: () => context.pubsub.publish('onHeaderViewportEnter'),
        onViewportLeave: () => context.pubsub.publish('onHeaderViewportLeave'),
      },
      [HEADER_WIDGET_COMPONENT_IDS.fulfillmentPicker]: {
        collapsed: () => !headerStore.isLoading && !headerStore.hasConfiguredDispatches,
      },
      [HEADER_WIDGET_COMPONENT_IDS.acceptOrders]: {
        text: () =>
          headerStore.hasAvailableDispatches
            ? this.t('header_olo.status.AcceptingOrders')
            : this.t('header_olo.status.NotAcceptingOrders'),
      },
      [HEADER_WIDGET_COMPONENT_IDS.statusIndicator]: {
        style: {
          backgroundColor: () =>
            headerStore.hasAvailableDispatches
              ? STATUS_INDICATOR_COLORS.ONLINE
              : STATUS_INDICATOR_COLORS.OFFLINE,
        },
      },
      [HEADER_WIDGET_COMPONENT_IDS.minOrder]: {
        text: () => {
          return this.t('header_olo.minOrder.exact', {
            amount: formatPrice(headerStore.minOrder),
          });
        },
      },
      [HEADER_WIDGET_COMPONENT_IDS.minOrderWrapper]: {
        hidden: () => headerStore.isLoading,
        collapsed: () =>
          (!headerStore.minOrder || headerStore.minOrder === '0') && !headerStore.isLoading,
      },
      [HEADER_WIDGET_COMPONENT_IDS.deliveryFee]: {
        text: () => {
          return headerStore.deliveryFee === '0'
            ? this.t('header_olo.deliveryFee.free')
            : this.t('header_olo.deliveryFee.exact', {
                amount: formatPrice(headerStore.deliveryFee),
              });
        },
      },
      [HEADER_WIDGET_COMPONENT_IDS.deliveryFeeWrapper]: {
        hidden: () => headerStore.isLoading,
        collapsed: () => !headerStore.deliveryFee && !headerStore.isLoading,
      },
      [HEADER_WIDGET_COMPONENT_IDS.freeDelivery]: {
        collapsed: () => !headerStore.freeDispatchPriceThreshold || headerStore.deliveryFee === '0',
        text: () => {
          return headerStore.freeDispatchPriceThreshold === '0'
            ? this.t('header_olo.deliveryFee.free')
            : this.t('header_olo.deliveryFee.freeAbove', {
                amount: formatPrice(headerStore.freeDispatchPriceThreshold),
              });
        },
      },
      [HEADER_WIDGET_COMPONENT_IDS.freeDeliveryWrapper]: {
        collapsed: () => !headerStore.freeDispatchPriceThreshold,
      },
      [HEADER_WIDGET_COMPONENT_IDS.addressInfo]: {
        text: () => {
          const prefix = headerStore.isDelivery
            ? this.t('header_olo.address.delivery')
            : this.t('header_olo.address.pickup');
          const { selectedAddress } = headerStore;
          return prefix + formatAddress(selectedAddress);
        },
      },
      [HEADER_WIDGET_COMPONENT_IDS.addressInfoLine]: {
        hidden: () => headerStore.isLoading,
        collapsed: () =>
          (!headerStore.hasConfiguredDispatches ||
            (headerStore.onlyDeliveryConfigured && !headerStore.hasAvailableDispatches)) &&
          !headerStore.isLoading,
      },
      [HEADER_WIDGET_COMPONENT_IDS.timeInfo]: {
        text: () => {
          const text = resolveTimeString({
            asapOptions: {
              isASAP: headerStore.startsNow,
              exactTime: headerStore.asapTimeExact,
              timeRange: headerStore.asapTimeRange,
            },
            selectedTime: headerStore.selectedTime,
            t: this.t,
            timezone: this.timezone,
            dispatchType: headerStore.dispatchType,
          });
          return text;
        },
      },
      [HEADER_WIDGET_COMPONENT_IDS.timeInfoLine]: {
        hidden: () => headerStore.isLoading,
        collapsed: () => !headerStore.hasAvailableDispatches && !headerStore.isLoading,
      },
      [HEADER_WIDGET_COMPONENT_IDS.addressChange]: {
        onClick: () => {
          context.biReporterService?.reportOloLiveSiteClickOnFulfillmentBiEvent({
            origin: LiveSiteClickFulfillmentOrigin.CHANGE_ADDRESS,
            isMemberLoggedIn: this.isMemberLoggedIn,
          });
          openDispatchModal(headerStore, headerStore.dispatchState);
        },
        label: () => {
          return headerStore.selectedAddress
            ? this.t('header_olo.address.delivery.change')
            : this.t('header_olo.address.delivery.add');
        },
        accessibility: {
          ariaAttributes: {
            describedBy: () => this.$w(HEADER_WIDGET_COMPONENT_IDS.addressInfo),
          },
        },
        collapsed: () => headerStore.dispatchType !== DispatchType.DELIVERY,
      },
      [HEADER_WIDGET_COMPONENT_IDS.timeChange]: {
        onClick: () => {
          context.biReporterService?.reportOloLiveSiteClickOnFulfillmentBiEvent({
            origin: LiveSiteClickFulfillmentOrigin.CHANGE_TIME,
            isMemberLoggedIn: this.isMemberLoggedIn,
          });
          openDispatchModal(headerStore, headerStore.dispatchState);
        },
        label: () => this.t('header_olo.time.change'),
        collapsed: () => headerStore.isASAP,
        accessibility: {
          ariaAttributes: {
            describedBy: () => this.$w(HEADER_WIDGET_COMPONENT_IDS.timeInfo),
          },
        },
      },
    });
  }

  initHeaderTexts({
    t,
    headerTitle,
    headerDescription,
  }: {
    t: TFunction;
    headerTitle: string;
    headerDescription: string;
  }) {
    this.$bindAll({
      [HEADER_WIDGET_COMPONENT_IDS.headerTitle]: {
        text: () => {
          return headerTitle || t('header_olo.default.title');
        },
      },
      [HEADER_WIDGET_COMPONENT_IDS.headerDescription]: {
        text: () => headerDescription || t('header_olo.default.description'),
      },
    });
  }
}
