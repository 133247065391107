import model from './model';
import { DishesController } from './dishesController';
import type { TFunction } from '@wix/yoshi-flow-editor';
import type { DishesWidgetProps } from '../../types/menusTypes';
import { DEFAULT_TIMEZONE } from 'root/api/consts';
import { getSiteLocale } from 'root/utils/siteDataUtils';

export default model.createController(({ $w, $widget, flowAPI }) => {
  const { controllerConfig, translations, environment, experiments } = flowAPI;
  const t = translations.t as TFunction;
  const { isMobile, isEditor } = environment;
  const { wixCodeApi } = controllerConfig;
  const isMemberLoggedIn = !!wixCodeApi.user.currentUser.loggedIn;
  const { timezone = DEFAULT_TIMEZONE } = wixCodeApi.site;
  const locale = getSiteLocale(flowAPI);

  const dishesController = new DishesController(
    $w,
    t,
    isMobile,
    experiments,
    timezone,
    locale,
    isMemberLoggedIn
  );

  let prevItemIds = [];

  $widget.onPropsChanged((prevProps, nextProps) => {
    const { menuId, sectionId, isTruncated, size, itemIds }: DishesWidgetProps = nextProps.data;
    const shouldInit =
      prevProps.data.menuId !== menuId ||
      prevProps.data.sectionId !== sectionId ||
      itemIds.length !== prevItemIds.length;

    if (shouldInit) {
      prevItemIds = itemIds;
      if (itemIds.length > 0) {
        dishesController.init(isEditor, menuId, sectionId, isTruncated, size, itemIds);
      } else {
        dishesController.setEmptyState();
      }
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const { menuId, sectionId, isTruncated, size, itemIds }: DishesWidgetProps =
        $widget.props.data;
      if (isEditor && itemIds.length > 0) {
        dishesController.deleteItemCounterInEditor(menuId);
      }
      const shouldInit = prevItemIds.length !== itemIds.length || itemIds.length === 0;
      if (shouldInit) {
        prevItemIds = itemIds;
        if (itemIds.length > 0) {
          dishesController.init(isEditor, menuId, sectionId, isTruncated, size, itemIds);
        } else {
          dishesController.setEmptyState();
        }
      }
    },
    exports: {},
  };
});
